import React from "react";
import { Container, Row, Col } from "react-bootstrap";


const PageTitle = ({title = 'Page title', subtitle = false, align="center"}) => {

    return (
        <Container as="section">
            <Row>
                <Col className="col-12">
                    <div className={`text-${align} mb-12 mb-lg-17`}>
                        <h1 className="font-size-10 font-weight-bold mb-8 page-title">
                            { title }
                        </h1>
                        {subtitle && ( <p className="px-5 px-lg-7 px-xl-9 px-xxl-15 mb-6">{ subtitle }</p> )}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default PageTitle