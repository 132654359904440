import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../../components/PageWrapper";
import styled from "styled-components";
import SEO from "../../../components/seo";
import BackButton from "../../../components/Core/BackButton";
import PageTitle from "../../../components/Core/PageTitle";
import NoAuthRedirect from "../../../components/Core/NoAuthRedirect";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const Content = styled.div`

    $ a:hover {
        color: silver;
    }

    & h2 {
        margin: 1.5rem 0;
    }
`

const BlogArticle = ({data, location}) => {
    const headerConfig = {
        paddingBottom: 10,
    };

    return (
        <>  
            <UnauthenticatedTemplate>
                <NoAuthRedirect location={location} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper headerConfig={headerConfig}>
                    <SEO title="Recent experience - Nicole Bianchi" />
                    <Content>
                        <BackButton />
                        <Container className="">
                            <Row className="">
                                <Col className="col-lg-2"></Col>
                                <Col className="col-lg-6">
                                    <PageTitle
                                        title="Nicole Bianchi shares her experience"
                                        align="left"
                                    />
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="col-lg-2 text-right">
                                    <div className="mb-4 border-top pt-7">
                                        {/* Recent experience */}
                                    </div>
                                    <div>
                                        {/* Last updated<br /> */}
                                        {/* 2 Jan, 2022 */}
                                    </div>
                                </Col>
                                <Col className="col-lg-6">
                                    <StaticImage
                                        src="photo_nicole_bianci_landscape.jpg"
                                        alt="Portrait of Nicole Bianci - smiling, corporate look"
                                        placeholder="blurred"
                                        layout="fixed"
                                        quality={100}
                                        width={480}
                                        style={{maxWidth: '100%'}}
                                        className="mb-10"
                                    />
                                    <p>
                                        I currently work as Legal Counsel at COFRA AG in Zug, advising the Holding, its businesses and the Corporate Foundations on legal matters. To learn more about COFRA's strategy in terms of acquiring new companies and for my own development, I went on a short-term assignment to Bregal in London to get better insights into all kinds of (legal) tasks in a private equity environment. Thanks to a great team spirit within the legal team and the help of HR, I was able to go to London in September 2021 and stayed there until the end of March 2022 (only with a short break in January due to the COVID situation - fortunately, working from home didn't cause any problems). 
                                    </p>
                                    <StaticImage
                                        src="photo_nicole_bianci_in_the_park.jpg"
                                        alt="Portrait of Nicole Bianci - smiling, corporate look"
                                        placeholder="blurred"
                                        layout="fixed"
                                        quality={100}
                                        width={480}
                                        style={{maxWidth: '100%'}}
                                        className="my-10"
                                    />
                                    <p>
                                        During my six months at Bregal, I worked on deals and fundraising for Bregal Milestone. Private equity is a fast-paced environment, so I was able to get involved from day one. The opportunity to work on multiple deals really helped me understand different types of negotiations, both in terms of minority investments and buyouts. In my opinion, working in a different environment also creates good external perspectives and builds bridges between companies. This ultimately improves cooperation between entities. I'm still in contact with the Bregal team and know that I can always reach out to them to benefit from their network or their understanding of specific legal topics.
                                    </p>
                                    <p>
                                        Even if 6 months seems sufficient for an assignment, time passes even faster in a new city and a new environment, so it's important to make sure you get sufficient (theoretical) training in your new role so that you can make the most of your short-term assignment. 
                                    </p>
                                    <p>
                                        Overall, I can only encourage everyone to take advantage of the career development opportunities available within our great international business.  
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Content>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}

export default BlogArticle;