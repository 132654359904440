import React from "react"
import PageWrapper from "../PageWrapper"
import { navigate } from "gatsby"
import queryString from "query-string"

// If not authenticated, redirect to SignIn page. Redirect has to be declared as fn component. When used as plain navigate function inside UnauthenticatedTemplate, it gets fired even if user is not logged in.
const NoAuthRedirect = ({location}) => {
    
    const query = location ? { redirectUri: location.pathname + location.search } : {}

    return (
        <PageWrapper
            headerConfig={{render:false}}
            footerConfig={{render:false}}
        >
            Loading...
            { navigate(`/sign-in?${queryString.stringify(query)}`) }
        </PageWrapper>
    )
}

export default NoAuthRedirect